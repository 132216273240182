/* eslint-disable prettier/prettier */
import { configResponsive, usePrevious, useResponsive } from 'ahooks'
import classNames from 'classnames'
import { createContext, Dispatch, memo, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import useI18n from 'src/hooks/use_i18n'
import useUserInfo from 'src/hooks/use_user_info'
import useUserMsg from 'src/hooks/use_user_msg'
import { CarrierInfoMarkDTO, IKV, NavbarDropdownMenuType, NavbarDropdownType } from 'src/types'
import ArrowButton from './arrow_button'
import styles from './index.module.scss'
import NavLeft from './nav_left'
import NavRight from './nav_right'
import NavItem from './navItem'
import { LabelConfig, NavConfig } from './types'

export const NavbarContext = createContext<{
  renderLabel: (label: LabelConfig) => ReactNode
  dropdownType?: NavbarDropdownType | string | null
  // setDropdownType?: (id: NavbarDropdownType | string | null) => void
  setCollapse?: Dispatch<SetStateAction<boolean>>
  setDropdownType?: Dispatch<SetStateAction<NavbarDropdownType | string | null>>
  lazyActiveHandleDropdownFn?: (type: NavbarDropdownMenuType | string, e: IKV, gaEvent?: boolean) => void
  handleDropdown?: (type: NavbarDropdownMenuType | string, e: IKV, gaEvent?: boolean) => void
  responsiveSize?: Record<string, boolean>
  imgHrefConfig: Record<string, string>
  handleLazyMouseLeave?: ({
    timerClean,
    dropdownTimerClean
  }: {
    timerClean?: boolean
    dropdownTimerClean?: boolean
  }) => void
  isMoveTabOpen: boolean
  collapse?: boolean
  handleNavigationClick?: (e: IKV, url: string, eventName: string, eventAction: IKV) => void
}>({
  renderLabel: () => null,
  dropdownType: null,
  setDropdownType: () => null,
  lazyActiveHandleDropdownFn: () => {},
  handleLazyMouseLeave: () => {},
  handleDropdown: () => {},
  isMoveTabOpen: false,
  responsiveSize: {},
  imgHrefConfig: {},
  collapse: true,
  setCollapse: () => null,
  handleNavigationClick: () => {}
})

interface NavbarProps {
  navConfig?: NavConfig
  carrierInfoMark?: CarrierInfoMarkDTO
}

function Navbar({ carrierInfoMark }: NavbarProps) {
  const { $t, activeLocale: locale, i18nFormatAll } = useI18n()
  const { userInfo, getUserInfo } = useUserInfo()
  const { getUserUnReadMsg } = useUserMsg({ getUserInfo })
  // 配置宽度断点
  configResponsive({
    isLgSize: 1200
  })
  // 获取到响应式宽度
  const responsiveSize = useResponsive()
  // 对应当前下拉的控件标识
  const [dropdownType, setDropdownType] = useState<NavbarDropdownType | string | null>(null)
  // 记录上一次的dropdownType
  const preDropdownType = usePrevious(dropdownType)
  // 移动端下拉控件
  const [collapse, setCollapse] = useState<boolean>(true)
  // 主要用于接受需要延迟切换菜单切换按钮的定时器引用
  const lazyTimer = useRef<number | undefined>(undefined)
  // 主要用于接受需要延迟切换菜单切换按钮的定时器引用
  const lazyDropdownTimer = useRef<number | undefined>(undefined)

  useEffect(() => {
    getUserInfo()
  }, [])

  useEffect(() => {
    if (userInfo?.isLogin) {
      getUserUnReadMsg()
    }
  }, [userInfo?.isLogin])

  // 选哟判断登录的下拉drop
  const handleDropdown = (type: NavbarDropdownType | string, e?: IKV, gaEvent: boolean = true) => {
    if (gaEvent && (e?.type == 'mouseenter' || e?.type == 'click')) {
      // 暂时注释后续看特殊情况处理
      // e?.preventDefault()
      window.ga?.sendGaEvent(`${type}_${e?.type == 'mouseenter' ? 'popup' : e?.type}`, {
        event_category: '导航',
        event_action: e?.type == 'mouseenter' ? '曝光' : '点击'
      })
    }

    if (type === NavbarDropdownType.MESSAGE && dropdownType !== type) {
      getUserUnReadMsg()
    }
    setDropdownType(dropdownType === type ? null : type)
  }

  // 需要lazy处理的顶部菜单事件
  const lazyActiveHandleDropdownFn = (type: NavbarDropdownMenuType | string, e?: IKV, gaEvent: boolean = true) => {
    if (lazyDropdownTimer.current !== undefined) {
      clearTimeout(lazyTimer.current)
    }
    if (dropdownType !== type) {
      lazyDropdownTimer.current = window.setTimeout(() => {
        handleDropdown(type, e, gaEvent)
      }, 100)
    }
  }

  // 用于鼠标移出事件的清除定时器
  const handleLazyMouseLeave = ({ timerClean = false, dropdownTimerClean = false }) => {
    if (timerClean && lazyTimer.current !== undefined) {
      clearTimeout(lazyTimer.current)
    }

    if (dropdownTimerClean && lazyDropdownTimer.current !== undefined) {
      clearTimeout(lazyDropdownTimer.current)
    }
  }

  /**
   *@Description: 用于处理下拉菜单点击防止触发顶部事件
   *@param {object} e 事件对象
   *@param {String} url 跳转链接
   *@param {String} eventName ga发送名
   *@param {Object} eventAction ga发送参数
   */
  const handleNavigationClick = (e: IKV, url: string, eventName: string, eventAction: IKV) => {
    e.preventDefault()
    e.stopPropagation() // 阻止事件冒泡
    window.ga?.sendGaEvent(eventName, eventAction)
    if (e.currentTarget.target === '_blank') {
      window.open(url)
    } else {
      window.location.href = url
    }
  }

  // 定义一套不同属性对应不同hreflang链接
  const imgHrefConfig: { [key: string]: string } = {
    [NavbarDropdownMenuType.CARRIERS_ETTLEIN]: `https://features.17track.net/${locale}/carriersettlein`,
    [NavbarDropdownMenuType.CARRIERS]: `https://www.17track.net/${locale}/carriers`,
    [NavbarDropdownMenuType.TRACKING_WIDGET]: `https://extcall.17track.net/${locale}`,
    [NavbarDropdownMenuType.HELP_CENTER]: `https://help.17track.net/hc/${locale}`,
    [NavbarDropdownMenuType.API_DOCUMENT]: `https://api.17track.net/${locale === 'zh-cn' ? 'zh-cn' : 'en'}/doc`,
    [NavbarDropdownMenuType.PARTNERS]: `https://links.17track.net/${locale}`,
    [NavbarDropdownMenuType.API]: `https://www.17track.net/${locale === 'zh-cn' ? 'zh-cn' : 'en'}/api`,
    [NavbarDropdownMenuType.MOBILE_APP]: `https://www.17track.net/${locale}/app`,
    [NavbarDropdownMenuType.SHOPIFY_APP]: `https://www.17track.net/${locale}/track_page?utm_source=carriers&utm_medium=referer&utm_campaign=20230130-carrier-open-shopify`
  }

  // 用于记录是否是指定tab间切换，切换样式效果
  const validDropdownTypes: NavbarDropdownType[] = [
    NavbarDropdownType.PRODUCT,
    NavbarDropdownType.RESOURCES,
    NavbarDropdownType.CARRIERS
  ]
  const isMoveTabOpen =
    dropdownType !== null &&
    validDropdownTypes.includes(dropdownType as NavbarDropdownType) &&
    validDropdownTypes.includes(preDropdownType as NavbarDropdownType)

  // 定义item内容对于type需要生成的结构类型
  const renderLabel = (label: LabelConfig): ReactNode => {
    // 针对没内容的情况不渲染
    if (!label.content || (typeof label.content === 'string' && label.content.trim() === '')) {
      return null
    }
    switch (label.type) {
      case 'image':
        return <img src={label.content as string} alt={label.alt} className={label.className} {...label.props} />

      case 'link':
        return (
          <a href={label.href} className={label.className} {...label.props}>
            {label.content}
          </a>
        )

      case 'text':
        return (
          <p className={label.className} {...label.props}>
            {label.content}
          </p>
        )

      case 'reactNode':
        return (
          <div className={label.className} {...label.props}>
            {label.content as ReactNode}
          </div>
        )

      default:
        return label.content
    }
  }

  // 用于配置菜单内容
  const defaultNavConfig: IKV = [
    {
      label: {
        type: 'reactNode',
        key: NavbarDropdownType.PRODUCT,
        content: (
          <ArrowButton
            buttonKey={NavbarDropdownType.PRODUCT}
            buttonType="dropDownNav"
            buttonText={$t('ResGlobalPHeaderMenus.global.__product')}
            isMove={dropdownType === NavbarDropdownType.PRODUCT}
          />
        )
      },
      key: NavbarDropdownType.PRODUCT,
      dropdown: [
        {
          label: {
            type: 'reactNode',
            labelTitle: $t('ResGENavigationMenu.items').itemsDict?.get('19')?._name,
            labelDesc: $t('ResWwwPHeadMenus.global.__shopifyAPPSubtitle'),
            children: {
              imgUrl: '/assets/images/nav/shopify_0903',
              contentDesc: $t('ResWwwPHeadMenus.global.__shopifyAPPDescription'),
              showMoreBtn: true
            },
            key: NavbarDropdownMenuType.SHOPIFY_APP
          }
        },
        {
          label: {
            type: 'reactNode',
            labelTitle: $t('ResGENavigationMenu.items').itemsDict?.get('18')?._name,
            labelDesc: i18nFormatAll($t('ResWwwPHeadMenus.global.__trackingApiSubtitle'), {
              carriersNumber: i18nFormatAll($t('ResWwwPHome.global.__score_totalVar'), [carrierInfoMark?.partner])
            }),
            target: '_blank',
            key: NavbarDropdownMenuType.API,
            children: {
              imgUrl: '/assets/images/nav/api_1118',
              target: '_blank',
              contentDesc: i18nFormatAll($t('ResWwwPHeadMenus.global.__trackingApiDescription'), {
                carriersNumber: i18nFormatAll($t('ResWwwPHome.global.__score_totalVar'), [carrierInfoMark?.partner])
              }),
              showMoreBtn: true
            }
          }
        },
        {
          label: {
            type: 'reactNode',
            labelTitle: `17TRACK ${$t('ResGENavigationMenuGroup.items').itemsDict?.get('2')?._name}`,
            labelDesc: $t('ResWwwPHeadMenus.global.__mobileAppSubtitle'),
            key: NavbarDropdownMenuType.MOBILE_APP,
            children: {
              imgUrl: '/assets/images/nav/app_0903',
              contentDesc: i18nFormatAll($t('ResWwwPHeadMenus.global.__mobileAppDescription'), {
                carriersNumber: i18nFormatAll($t('ResWwwPHome.global.__score_totalVar'), [carrierInfoMark?.partner]),
                languagesNumber: i18nFormatAll($t('ResWwwPHome.global.__score_totalVar'), [carrierInfoMark?.language]),
                trackingRate: i18nFormatAll($t('ResWwwPHome.global.__score_totalTrackNumVar'), [
                  carrierInfoMark?.cumulativeTracking
                ])
              }),
              showMoreBtn: true
            }
          }
        }
      ],
      props: {
        'data-dropdown-toggle': ''
      }
    },
    {
      label: {
        type: 'reactNode',
        key: NavbarDropdownType.RESOURCES,
        content: (
          <ArrowButton
            buttonKey={NavbarDropdownType.RESOURCES}
            buttonType="dropDownNav"
            buttonText={$t('ResWwwPHeadMenus.global.__resources')}
            isMove={dropdownType === NavbarDropdownType.RESOURCES}
          />
        )
      },
      key: NavbarDropdownType.RESOURCES,
      dropdown: [
        {
          label: {
            type: 'reactNode',
            labelTitle: $t('ResGEToolsMenus.items').itemsDict?.get('01')?._name,
            labelDesc: $t('ResWwwPHeadMenus.global.__trackingWidgetSubtitle'),
            key: NavbarDropdownMenuType.TRACKING_WIDGET,
            children: {
              imgUrl: '/assets/images/nav/tracking_widget_0903',
              contentDesc: $t('ResWwwPHeadMenus.global.__trackingWidgetDescription'),
              showMoreBtn: true
            }
          }
        },
        {
          label: {
            type: 'reactNode',
            labelTitle: $t('ResWwwPHeadMenus.global.__partners'),
            labelDesc: $t('ResWwwPHeadMenus.global.__connectWithTop'),
            key: NavbarDropdownMenuType.PARTNERS,
            children: {
              imgUrl: '/assets/images/nav/partners_0903',
              contentDesc: $t('ResWwwPHeadMenus.global.__accessTracking'),
              showMoreBtn: true
            }
          }
        },
        {
          label: {
            type: 'reactNode',
            labelTitle: $t('ResWwwPHeadMenus.global.__trackingApiDocument'),
            labelDesc: $t('ResWwwPHeadMenus.global.__trackingApiDocumentSubtitle'),
            target: '_blank',
            key: NavbarDropdownMenuType.API_DOCUMENT,
            children: {
              imgUrl: '/assets/images/nav/api_doc_0903',
              target: '_blank',
              contentDesc: $t('ResWwwPHeadMenus.global.__trackingApiDocumentDescription'),
              showMoreBtn: true
            }
          }
        },
        {
          label: {
            type: 'reactNode',
            labelTitle: $t('ResWwwEFooterMenus.items').itemsDict?.get('10')?._name,
            labelDesc: $t('ResWwwPHeadMenus.global.__helpCenterSubtitle'),
            target: '_blank',
            key: NavbarDropdownMenuType.HELP_CENTER,
            children: {
              imgUrl: '/assets/images/nav/help_center_0903',
              target: '_blank',
              contentDesc: $t('ResWwwPHeadMenus.global.__helpCenterDescription'),
              showMoreBtn: true
            }
          }
        }
      ],
      props: {
        'data-dropdown-toggle': ''
      }
    },
    {
      label: {
        type: 'reactNode',
        key: NavbarDropdownType.CARRIERS,
        content: (
          <ArrowButton
            buttonKey={NavbarDropdownType.CARRIERS}
            buttonType="dropDownNav"
            buttonText={$t('ResWwwPHeadMenus.global.__carriers')}
            isMove={dropdownType === NavbarDropdownType.CARRIERS}
          />
        )
      },
      key: NavbarDropdownType.CARRIERS,
      dropdown: [
        {
          label: {
            type: 'reactNode',
            labelTitle: $t('ResWwwPHeadMenus.global.__carriers'),
            labelDesc: i18nFormatAll($t('ResWwwPHeadMenus.global.__carriersSubtitle'), {
              carriersNumber: i18nFormatAll($t('ResWwwPHome.global.__score_totalVar'), [carrierInfoMark?.partner]),
              airlinesNumber: i18nFormatAll($t('ResWwwPHome.global.__score_totalVar'), [carrierInfoMark?.airwaybill])
            }),
            key: NavbarDropdownMenuType.CARRIERS,
            children: {
              imgUrl: '/assets/images/nav/carriers_1118',
              contentDesc: i18nFormatAll($t('ResWwwPHeadMenus.global.__carriersDescription'), {
                carriersNumber: i18nFormatAll($t('ResWwwPHome.global.__score_totalVar'), [carrierInfoMark?.partner]),
                countriesNumber: i18nFormatAll($t('ResWwwPHome.global.__score_totalVar'), [carrierInfoMark?.country])
              }),
              showMoreBtn: true
            }
          }
        },
        {
          label: {
            type: 'reactNode',
            labelTitle: $t('ResGlobalPHeaderMenus.global.__carrierIntegration'),
            labelDesc: $t('ResWwwPHeadMenus.global.__carrierIntegrationSubtitle'),
            key: NavbarDropdownMenuType.CARRIERS_ETTLEIN,
            children: {
              imgUrl: '/assets/images/nav/carriers_integration_0903',
              contentDesc: i18nFormatAll($t('ResWwwPHeadMenus.global.__carrierIntegrationDescription'), {
                carriersNumber: i18nFormatAll($t('ResWwwPHome.global.__score_totalVar'), [carrierInfoMark?.partner])
              }),
              showMoreBtn: true
            }
          }
        }
      ],
      props: {
        'data-dropdown-toggle': ''
      }
    },
    {
      label: {
        type: 'reactNode',
        key: NavbarDropdownType.DEVELOPER,
        content: (
          <a
            className={styles['btn-link']}
            href={imgHrefConfig[NavbarDropdownMenuType.API]}
            target="_blank"
            onClick={(e) => {
              handleNavigationClick(e, imgHrefConfig[NavbarDropdownMenuType.API], `Developers_click`, {
                event_category: '导航',
                event_action: '点击'
              })
            }}
          >
            {$t('ResGPHeaderMenus.global.__api')}
          </a>
        ),
        props: {
          className: classNames('w-100')
        }
      },
      key: NavbarDropdownType.DEVELOPER,
      props: {
        className: classNames(styles['nav-item'], styles['hover-secondary'], styles['nav-item-label']),
        onMouseEnter: (e) => {
          if (!responsiveSize['isLgSize']) return
          lazyActiveHandleDropdownFn(NavbarDropdownType.DEVELOPER, e)
        },
        onMouseLeave: () => {
          if (!responsiveSize['isLgSize']) return
          handleLazyMouseLeave({ timerClean: true, dropdownTimerClean: true })
        }
      }
    }
  ]

  useEffect(() => {
    const handler = function (event) {
      const targetElement = event.target
      const isDropdownToggle = (targetElement as any)?.closest('[data-dropdown-toggle]') // 目标元素及其父元素是否包含 data-dropdown-toggle 属性
      if (!isDropdownToggle) {
        setDropdownType(null)
      }
    }
    document.addEventListener('click', handler)

    return () => {
      document.removeEventListener('click', handler)
      // 清理延迟定时器（延迟是处理切换tab与切换菜单时，防止移动过快导致误切）
      clearTimeout(lazyTimer.current)
      clearTimeout(lazyDropdownTimer.current)
    }
  }, [])

  return (
    <NavbarContext.Provider
      value={{
        renderLabel,
        dropdownType,
        setDropdownType,
        handleLazyMouseLeave,
        handleDropdown,
        isMoveTabOpen,
        lazyActiveHandleDropdownFn,
        responsiveSize,
        imgHrefConfig,
        handleNavigationClick,
        collapse,
        setCollapse
      }}
    >
      <nav
        className={styles.navbar}
        onMouseLeave={() => {
          if (!responsiveSize['isLgSize']) return
          // 需要判断是否移出导航区域，移出则将触发type置空，防止对应type组件active
          if (validDropdownTypes.includes(dropdownType as NavbarDropdownType)) {
            setDropdownType(null)
          }
        }}
      >
        <Container className={styles['navbar-container']}>
          <Row className={classNames('w-100 justify-content-between m-0')}>
            <NavLeft />
            <Col className={classNames('hidden-md ', styles['navbar-center'])}>
              {defaultNavConfig?.map((item, index) => <NavItem key={`center-${index}`} item={item} />)}
            </Col>
            <NavRight
              onMouseEnter={() => {
                if (!responsiveSize['isLgSize']) return
                // 需要判断是否移出导航区域，移出则将触发type置空，防止对应type组件active
                if (validDropdownTypes.includes(dropdownType as NavbarDropdownType)) {
                  setDropdownType(null)
                }
              }}
            />
          </Row>
          <Row className={classNames('w-100 d-none', { [styles.expanded]: !collapse })}>
            <Col className={classNames(styles['navbar-center'])} xs="auto" md="auto">
              {defaultNavConfig?.map((item, index) => <NavItem key={`expanded-center-${index}`} item={item} />)}
            </Col>
            <NavRight showHamburger={false} />
          </Row>
        </Container>
      </nav>
    </NavbarContext.Provider>
  )
}

export default memo(Navbar)
